<template>
  <div>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" /> {{$t('Labels.MyData')}}
        </template>
        <b-card>
          <b-row align-h="end">
            <b-col>
              <b-button
                variant="flat-primary"
                class="btn-icon float-right"
                @click="editProfile = true"
                v-if="!editProfile"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="flat-primary"
                class="btn-icon float-right"
                @click="editProfile = false"
                v-if="editProfile"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-form
            class=""
            @submit.prevent
            v-if="editProfile"
          >
          <validation-observer ref="userValidation">
            <b-row class="mb-1">
              <b-col cols="12" md="6" class="text-center">
                <b-avatar
                  size="90px"
                  class="shadow"
                  :src="editUser.avatar"
                />
              </b-col>
            </b-row>
            <b-row class="mb-1">
              <b-col cols="12" md="6" class="text-center">
                <input type="file" id="file" ref="file" @change="handleFileUpload()" class="hidden"/>
                <b-button
                  variant="primary"
                  class="mt-1"
                  @click="$refs.file.click()"
                >
                  {{$t("Labels.ChangeImage")}}
                </b-button>
                <b-button
                  variant="primary"
                  class="mt-1 ml-1"
                  @click="saveImage()"
                >
                  {{$t("Labels.Save")}}
                </b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('Labels.Name')"
                  label-for="name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="editUser.name"
                      :state="errors.length > 0 ? false:null"
                      name="name"
                      :placeholder="$t('Labels.Name')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('Labels.LastName')"
                  label-for="lastName"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="lastName"
                    rules="required"
                  >
                    <b-form-input
                      id="lastName"
                      v-model="editUser.lastName"
                      :state="errors.length > 0 ? false:null"
                      name="lastName"
                      :placeholder="$t('Labels.LastName')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('Labels.Phone')"
                  label-for="phone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="phone"
                    rules="required|digits:10"
                  >
                    <b-form-input
                      id="phone"
                      v-model="editUser.telephone"
                      :state="errors.length > 0 ? false:null"
                      name="phone"
                      :placeholder="$t('Labels.Phone')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6" offset-md="6">
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="validationForm"
                  class="mt-3"
                >
                  {{$t("Labels.Save")}}
                </b-button>
              </b-col>
            </b-row>
            </validation-observer>
          </b-form>
          <div v-else>
            <avatarItem
              :img="user.avatar"
              :value="user.name"
              :description="user.role"
            />
            <valueItem
              class="mt-1"
              :value="user.telephone"
              :description="$t('Labels.Phone')"
              img="phone"
            />
          </div>
          <!--<b-row v-else>
            <b-col cols="12" md="auto">
              <div  class="text-center">
                <b-avatar
                  size="70px"
                  class="shadow"
                  :src="$store.state.session.AppActiveUser().avatar || null"
                />
              </div>
              <div class="text-center mt-1 font-weight-bold">
                {{$store.state.session.AppActiveUser().displayName}}
              </div>
              <div class="text-center">
                {{userRole[$store.state.session.AppActiveUser().userRole] || ''}}
              </div>
            </b-col>
          </b-row>-->
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" /> {{$t('Labels.ChangePassword')}}
        </template>
        <b-card>
          <b-form
            class=""
            @submit.prevent
          >
            <validation-observer ref="passwordValidation">
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('Labels.OldPassword')"
                  label-for="oldPassword"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    rules="required"
                  >
                    <b-form-input
                      id="oldPassword"
                      v-model="changePassword.oldPassword"
                      :state="errors.length > 0 ? false:null"
                      name="password"
                      :placeholder="$t('Password')"
                      type="password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('Password')"
                  label-for="password"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    rules="required|password"
                  >
                    <b-form-input
                      id="password"
                      v-model="changePassword.password"
                      :state="errors.length > 0 ? false:null"
                      name="password"
                      :placeholder="$t('Password')"
                      type="password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="6">
                <b-form-group
                  :label="$t('Labels.ConfirmPassword')"
                  label-for="confirmPassword"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="confirmPassword"
                    rules="required|confirmed:password"
                  >
                    <b-form-input
                      id="confirmPassword"
                      v-model="changePassword.confirmPassword"
                      :state="errors.length > 0 ? false:null"
                      name="confirmPassword"
                      :placeholder="$t('Labels.ConfirmPassword')"
                      type="password"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
            <b-col cols="12" md="6" offset-md="6">
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  @click="passwordValidation"
                  class="mt-3"
                >
                  {{$t("Labels.Save")}}
                </b-button>
              </b-col>
            </b-row>
            </validation-observer>
          </b-form>
        </b-card>
      </b-tab>
      <b-tab lazy @click="getResponse()">
        <template #title>
          <feather-icon icon="HeartIcon" /> {{$t('Labels.HealthInfo')}}
        </template>
        <b-card>
          <b-row align-h="end">
            <b-col>
              <b-button
                variant="flat-primary"
                class="btn-icon float-right"
                @click="edit = true"
                v-if="!edit"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                variant="flat-primary"
                class="btn-icon float-right"
                @click="edit = false"
                v-if="edit"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
          </b-row>
          <b-form
            class=""
            @submit.prevent
            v-if="edit"
          >
            <validation-observer ref="infoValidation">
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Labels.Allergies')"
                    label-for="allergies"
                  >
                    <b-form-input
                      id="allergies"
                      v-model="infoEdit.allergies"
                      name="allergies"
                      :placeholder="$t('Labels.Allergies')"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Labels.BloodType')"
                    label-for="blood_type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="blood_type"
                      rules="required"
                    >
                      <b-form-select
                        name="blood_type"
                        :state="errors.length > 0 ? false:null"
                        :options="bloodTypes"
                        v-model="infoEdit.blood_type"
                        :clearable="false"
                      >
                        <template #first>
                          <b-form-select-option :value="null" disabled>{{$tc('Labels.SelectOption')}}</b-form-select-option>
                        </template>
                      </b-form-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Labels.ChronicConditions')"
                    label-for="chronic_conditions"
                  >
                      <b-form-input
                        id="chronic_conditions"
                        v-model="infoEdit.chronic_conditions"
                        name="chronic_conditions"
                        :placeholder="$t('Labels.ChronicConditions')"
                      />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Labels.HealthIndications')"
                    label-for="special_health_indications"
                  >
                      <b-form-input
                        id="special_health_indications"
                        v-model="infoEdit.special_health_indications"
                        name="special_health_indications"
                        :placeholder="$t('Labels.HealthIndications')"
                      />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6" offset-md="6">
                  <b-button
                    type="submit"
                    variant="primary"
                    block
                    @click="infoValidation()"
                    class="mt-3"
                  >
                    {{$t("Labels.Save")}}
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-form>
          <div v-else>
            <valueItem
              :value="response.allergies"
              :description="$t('Labels.Allergies')"
              img="pill"
            />
            <valueItem
              :value="response.blood_type"
              :description="$t('Labels.BloodType')"
              img="blood-bag"
            />
            <valueItem
              :value="response.chronic_conditions"
              :description="$t('Labels.ChronicConditions')"
              img="needle"
            />
            <valueItem
              :value="response.special_health_indications"
              :description="$t('Labels.HealthIndications')"
              img="medical-bag"
            />
          </div>
        </b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import valueItem from '@core/spore-components/valueItem/valueItem.vue'
import avatarItem from '@core/spore-components/avatarItem/avatarItem.vue'
import request from '@/libs/request/index'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BFormSelect,
  BFormSelectOption,
  BTabs,
  BTab,
  BAvatar
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    valueItem,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    BTabs,
    BTab,
    BAvatar,
    avatarItem
  },
  data () {
    return {
      user: {
        avatar: '',
        name: '',
        role: '',
        telephone: ''
      },
      editUser: {
        avatar: '',
        name: '',
        lastName: '',
        telephone: ''
      },
      response: {},
      changePassword: {
        oldPassword: '',
        password: '',
        confirmPassword: ''
      },
      required,
      edit: false,
      infoEdit: {},
      bloodTypes: [
        { value: 'A+', text: 'A+' },
        { value: 'A-', text: 'A-' },
        { value: 'B+', text: 'B+' },
        { value: 'B-', text: 'B-' },
        { value: 'AB+', text: 'AB+' },
        { value: 'AB-', text: 'AB-' },
        { value: 'O+', text: 'O+' },
        { value: 'O-', text: 'O-' }
      ],
      editProfile: false,
      userRole: {
        TP: this.$t('Labels.Carrier'),
        TA: this.$t('Labels.Admin'),
        TO: this.$t('Labels.Operator')
      },
      file: ''
    }
  },
  methods: {
    async getProfile () {
      const params = {
        url: 'user/avatar',
        method: 'GET'
      }
      await request(params).then(data => {
        this.user.name = data.data.data.first_name + ' ' + data.data.data.last_name
        this.editUser.name = data.data.data.first_name
        this.editUser.lastName = data.data.data.last_name
        this.user.telephone = data.data.data.phone_number
        this.editUser.telephone = data.data.data.phone_number
        this.user.avatar = 'data:image/jpeg;base64,' + data.data.data.avatar
        this.editUser.avatar = 'data:image/jpeg;base64,' + data.data.data.avatar
      })
    },
    async getResponse () {
      const params = {
        url: 'tracker_users',
        method: 'GET'
      }
      await request(params).then(data => {
        this.response = data.data.data
        this.infoEdit.allergies = this.response.allergies
        this.infoEdit.blood_type = this.response.blood_type
        this.infoEdit.chronic_conditions = this.response.chronic_conditions
        this.infoEdit.special_health_indications = this.response.special_health_indications
      })
    },
    infoValidation () {
      this.$refs.infoValidation.validate().then(success => {
        if (success) {
          this.saveInfo()
        }
      })
    },
    async saveInfo () {
      const params = {
        url: 'tracker_users/health_info',
        method: 'PUT',
        params: this.infoEdit
      }
      await request(params).then(response => {
        this.response.allergies = this.infoEdit.allergies
        this.response.blood_type = this.infoEdit.blood_type
        this.response.chronic_conditions = this.infoEdit.chronic_conditions
        this.response.special_health_indications = this.infoEdit.special_health_indications
        this.edit = false
      })
    },
    async add () {
      const params = {
        url: 'user',
        method: 'PUT',
        params: {
          phone_number: this.editUser.telephone,
          first_name: this.editUser.name,
          last_name: this.editUser.lastName
        }
      }
      await request(params).then(response => {
        this.user.telephone = this.editUser.telephone.toString()
        this.user.name = this.editUser.name + ' ' + this.editUser.lastName
        this.editProfile = false
        this.$store.commit('session/UPDATE_USER_INFO', {
          displayName: this.editUser.name + ' ' + this.editUser.lastName
        })
      })
    },
    validationForm () {
      this.$refs.userValidation.validate().then(success => {
        if (success) {
          this.add()
        }
      })
    },
    handleFileUpload () {
      this.file = this.$refs.file.files[0]
      const reader = new FileReader()

      // Leemos el archivo subido y se lo pasamos a nuestro fileReader
      reader.readAsDataURL(this.$refs.file.files[0])

      // Le decimos que cuando este listo ejecute el código interno
      const app = this
      reader.onload = function () {
        app.editUser.avatar = reader.result
      }
    },
    async updatePassword () {
      const params = {
        url: 'user/password',
        method: 'PUT',
        params: {
          oldPass: btoa(this.changePassword.oldPassword),
          newPass: btoa(this.changePassword.password),
          reNewPass: btoa(this.changePassword.confirmPassword)
        }
      }
      await request(params).then(response => {
        this.changePassword = {
          oldPassword: '',
          password: '',
          confirmPassword: ''
        }
      })
    },
    passwordValidation () {
      this.$refs.passwordValidation.validate().then(success => {
        if (success) {
          this.updatePassword()
        }
      })
    },
    async saveImage () {
      const form = new FormData()
      form.append('file', this.file)
      const params = {
        url: 'user/avatar',
        method: 'PUT',
        params: form
      }
      await request(params).then(response => {
        this.user.avatar = this.editUser.avatar.toString()
        localStorage.setItem('avatar', this.editUser.avatar.toString())
      })
    }
  },
  created () {
    this.user.role = this.userRole[this.$store.state.session.AppActiveUser().userRole]
    this.getProfile()
  }
}
</script>
<style lang="scss" scoped>
</style>
